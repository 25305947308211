import StimulusIntegration from 'integration/stimulus_integration/stimulus_integration';
import Player from 'modules/player/player';

StimulusIntegration.registerContext(import.meta.glob('../library/javascript/**/*_controller.js', { eager: true }));

StimulusIntegration.registerContext(import.meta.glob('../../components/library/**/*_component.js', { eager: true }));
StimulusIntegration.registerContext(
  import.meta.glob('../../components/alex_fuse/attendance_show/form_component/**/*_component.js', {
    eager: true,
  }),
);

window.Player = Player;
